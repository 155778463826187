import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ReactHelmet from "react-helmet"
import constants from "../../utils/constants"

const brandFullName = constants.BRAND_FULLNAME

const Helmet = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          author
          description
          baseUrl
          twitterCreator
          defaultImage
        }
      }
    }
  `)

  // const defaults = data.site.siteMetadata

  return (
    <ReactHelmet
      title={data.site.siteMetadata.title}
      meta={[
        { name: "description", content: { brandFullName } },
        { name: "keywords", content: "sewing machines, sewing classes" },
      ]}
    >
      <html lang="en" />
    </ReactHelmet>
  )
}

export default Helmet
