import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container } from "reactstrap"

import constants from "../../utils/constants"

const productionUrl = constants.PRODUCTION_URL
const brandFull = constants.BRAND_FULLNAME

const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          revisionVersion
        }
      }
    }
  `)

  return (
    <footer className="text-center wrapper py-4" id="wrapper-footer">
      <Container>
        © {new Date().getFullYear()}
        {` `}
        <a href={productionUrl}>{brandFull}</a>{" "}
        <small>(Rev. {data.site.siteMetadata.revisionVersion})</small>
      </Container>
    </footer>
  )
}

export default Footer
