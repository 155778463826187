import { 
  useContext,
  useCallback,
  useMemo,
} from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { CartContext } from "../contexts/CartContext";
import { updateCartQtyQuery } from "../graphql/mutations";

const useUpdateCartQty = () => {
  const { state, actions: cartActions } = useContext(CartContext);
  const [updateQtyMutation] = useMutation(gql`${updateCartQtyQuery}`);
  // const [inProgress, setInProgress] = useState(false);

  const updateQtyHandler = useCallback(async (productId, qty) => {
    const productToUpdate = state.items.find(
      product => product.productId === productId
    );
    if (productToUpdate) {
      // setInProgress(true);

      const updateProductQtyInput = {
        clientMutationId: productToUpdate.clientMutationId,
        items: [
          {
            key: productToUpdate.key,
            quantity: qty,
          },
        ],
      }

      cartActions.updateQty(productId, qty)

      try {
        const { data } = await updateQtyMutation({
          variables: {
            input: updateProductQtyInput,
          },
        })

        if (data && data.updateItemQuantities) {
          const updatedProduct = data.updateItemQuantities.items.find(
            product => product.key === productToUpdate.key
          )
          if (updatedProduct) {
            //just re-updating
            console.log("Update qty success", data.updateItemQuantities)
            cartActions.updateQty(productId, updatedProduct.quantity)
          }
        }
      } catch (e) {
        cartActions.updateQty(productId, productToUpdate.quantity)
      }
    }
  }, [updateQtyMutation, cartActions, state]);

  return useMemo(() => ({
    updateQtyHandler,
    // inProgress,  
  }), [updateQtyHandler]);
}

export {
  useUpdateCartQty
}