/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Header from "./header"
import Helmet from "./Helmet"
import Footer from "./footer"
import UpdateNotification from "./UpdateNotification"

// import "../css/main.css"

const Wrapper = styled.div`
  min-height: 100vh;
`

const Layout = ({ children, location, background }) => {
  return (
    <div className={`${location === "home" ? "layout-homepage" : "layout"}`}>
      <Wrapper>
        <Helmet />
        <Header />

        <main className="site-main" id="main">
          <div
            className={`${
              background === "image-background" ? "" : "search-spacer"
            } `}
          >
            <UpdateNotification />
            {children}
          </div>
        </main>
      </Wrapper>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
