export const freeShippingQuery = `
  query FreeShippingQuery {
    wpgraphql {
      themeGeneralSettings {
        ACFShippingSiteWide {
          amountForFreeShipping
          shippingSitewide
        }
      }
    }
  }
`

export const GET_PRODUCTS = `
  query GET_PRODUCTS($first: Int $after: String $category: String) {
    products(first: $first after: $after, where: { visibility: VISIBLE , type: SIMPLE, category: $category }) {
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        productId
        slug
        id
        name
        shortDescription
        description
        ... on SimpleProduct {
          price
          stockStatus
          stockQuantity
          onSale
          regularPrice
          salePrice
        }
        image {
          altText
          uri
          sourceUrl
        }
        galleryImages {
          edges {
            node {
              sourceUrl
              altText
              id
              srcSet
            }
          }
        }
        productCategories {
          nodes {
            name
            slug
          }
        }
        productTags {
          nodes {
            name
            slug
          }
        }
        ACFEcommerce {
          ecommerceAndShipping
          freeGiftWithSignup
          ground {
            zone1
            zone2
            zone3
            zone4
            zone5
            zone6
            zone7
            zone8
          }
          secondDay {
            zone1
            zone2
            zone3
            zone4
            zone5
            zone6
            zone7
            zone8
          }
        }
      }
    }
  }
`

export const GET_FEATURE_PRODUCT = `
  query GET_FEATURE_PRODUCT($slug: String) {
    products(where: {slug: $slug}) {
      nodes {
        productId
        slug
        id
        name
        shortDescription
        description
        ... on SimpleProduct {
          price
          stockStatus
          stockQuantity
          onSale
          regularPrice
          salePrice
        }
        image {
          altText
          uri
          sourceUrl
        }
        galleryImages {
          edges {
            node {
              sourceUrl
              altText
              id
              srcSet
            }
          }
        }
        productCategories {
          nodes {
            name
            slug
          }
        }
        productTags {
          nodes {
            name
            slug
          }
        }
        ACFEcommerce {
          ecommerceAndShipping
          freeGiftWithSignup
          ground {
            zone1
            zone2
            zone3
            zone4
            zone5
            zone6
            zone7
            zone8
          }
          secondDay {
            zone1
            zone2
            zone3
            zone4
            zone5
            zone6
            zone7
            zone8
          }
        }
      }
    }
  }
`

export const GET_LIVESTREAM_FIELDS = `
  query MyQuery {
    themeGeneralSettings {
      livestream_fields {
        featuredProduct
        liveStreamVideoUrl,
        fieldGroupName
      }
    }
  }
`
