import { useRef, useEffect, useState } from "react";
import VimeoPlayer from "@vimeo/player";

const useVimeoPlayer = (pageSlug, onTimeUpdate) => {
  const vimeoTimeStampRef = useRef();
  const iframeRef = useRef();
  const storageKey = `videoTimeStamp_${pageSlug}`;

  useEffect(() => {
    if (iframeRef.current) {
      const player = new VimeoPlayer(iframeRef.current);
      
      player.on("loaded", () => {
        const timeStampJson = localStorage.getItem(storageKey);
  
        if (timeStampJson) {
          const timeStamp = JSON.parse(timeStampJson);
          player.setCurrentTime(timeStamp.seconds);

          if (!timeStamp.pause) player.play();
        }
      });
  
      player.on("timeupdate", (time) => {
        vimeoTimeStampRef.current = time;
        if (onTimeUpdate) onTimeUpdate(time);
      });
 
      player.on("ended", () => {
        vimeoTimeStampRef.current = null;
      });
  
      player.on("pause", () => {
        vimeoTimeStampRef.current = {
          ...vimeoTimeStampRef.current,
          pause: true,
        };
      });
    }

    return () => {
      if (vimeoTimeStampRef.current) {
        localStorage.setItem(storageKey, JSON.stringify(vimeoTimeStampRef.current))
      }
    }
  }, [iframeRef.current, onTimeUpdate]);

  return {
    iframeRef,
  }
}

export default useVimeoPlayer;