import validator from "validator"
import isEmpty from "./isEmpty"
import config from "../../utils/constants"

const validateAndSanitizeCheckoutForm = data => {
  let errors = {}
  let sanitizedData = {}

  /**
   * Set the firstName value equal to an empty string if user has not entered the firstName, otherwise the Validator.isEmpty() wont work down below.
   * Note that the isEmpty() here is our custom function defined in is-empty.js and
   * Validator.isEmpty() down below comes from validator library.
   * Similarly we do it for for the rest of the fields
   */
  //billing
  data.bfirstName = !isEmpty(data.bfirstName) ? data.bfirstName : ""
  data.blastName = !isEmpty(data.blastName) ? data.blastName : ""
  data.bcompanyName = !isEmpty(data.bcompanyName) ? data.bcompanyName : ""
  data.bstreetAddressOne = !isEmpty(data.bstreetAddressOne)
    ? data.bstreetAddressOne
    : ""
  data.bstreetAddressTwo = !isEmpty(data.bstreetAddressTwo)
    ? data.bstreetAddressTwo
    : ""
  data.bcity = !isEmpty(data.bcity) ? data.bcity : ""
  data.bstate = !isEmpty(data.bstate) ? data.bstate : ""
  data.bpostCode = !isEmpty(data.bpostCode) ? data.bpostCode : ""
  data.bphone = !isEmpty(data.bphone) ? data.bphone : ""
  data.bemail = !isEmpty(data.bemail) ? data.bemail : ""
  // data.bcreateAccount = !isEmpty(data.bcreateAccount) ? data.bcreateAccount : ""
  data.bborderNotes = !isEmpty(data.bborderNotes) ? data.bborderNotes : ""
  data.methodId = !isEmpty(data.methodId) ? data.methodId : ""

  //shipping
  data.firstName = !isEmpty(data.firstName) ? data.firstName : ""
  data.lastName = !isEmpty(data.lastName) ? data.lastName : ""
  data.companyName = !isEmpty(data.companyName) ? data.companyName : ""
  data.streetAddressOne = !isEmpty(data.streetAddressOne)
    ? data.streetAddressOne
    : ""
  data.streetAddressTwo = !isEmpty(data.streetAddressTwo)
    ? data.streetAddressTwo
    : ""
  data.city = !isEmpty(data.city) ? data.city : ""
  data.state = !isEmpty(data.state) ? data.state : ""
  data.postCode = !isEmpty(data.postCode) ? data.postCode : ""
  data.phone = !isEmpty(data.phone) ? data.phone : ""
  data.email = !isEmpty(data.email) ? data.email : ""
  data.borderNotes = !isEmpty(data.orderNotes) ? data.orderNotes : ""
  
  /**
   * Checks for error if required is true
   * and adds Error and Sanitized data to the errors and sanitizedData object
   *
   * @param {String} fieldName Field name e.g. First name, last name
   * @param {String} errorContent Error Content to be used in showing error e.g. First Name, Last Name
   * @param {Integer} min Minimum characters required
   * @param {Integer} max Maximum characters required
   * @param {String} type Type e.g. email, bphone etc.
   * @param {boolean} required Required if required is passed as false, it will not validate error and just do sanitization.
   */
  const addErrorAndSanitizedData = (
    fieldName,
    errorContent,
    min,
    max,
    type = "",
    required
  ) => {
    const postCodeLocale = config.postCodeLocale ? config.postCodeLocale : ""
    /**
     * Please note that this isEmpty() belongs to validator and not our custom function defined above.
     *
     * Check for error and if there is no error then sanitize data.
     */
    if (!validator.isLength(data[fieldName], { min, max })) {
      errors[fieldName] = `${errorContent} must be ${min} to ${max} characters`
    }

    if ("email" === type && !validator.isEmail(data[fieldName])) {
      errors[fieldName] = `${errorContent} is not valid`
    }

    if ("phone" === type && !validator.isMobilePhone(data[fieldName])) {
      errors[fieldName] = `${errorContent} is not valid`
    }

    if (
      "postCode" === type &&
      postCodeLocale &&
      !validator.isPostalCode(data[fieldName], postCodeLocale)
    ) {
      errors[fieldName] = `${errorContent} is not valid`
    }

    if (required && validator.isEmpty(data[fieldName])) {
      errors[fieldName] = `${errorContent} is required`
    }

    // If no errors
    if (!errors[fieldName]) {
      sanitizedData[fieldName] = validator.trim(data[fieldName])
      sanitizedData[fieldName] =
        "email" === type
          ? validator.normalizeEmail(sanitizedData[fieldName])
          : sanitizedData[fieldName]
      sanitizedData[fieldName] = validator.escape(sanitizedData[fieldName])
    }
  }

  //billing

  addErrorAndSanitizedData("bfirstName", "First name", 2, 35, "string", true)
  addErrorAndSanitizedData("blastName", "Last name", 2, 35, "string", true)
  addErrorAndSanitizedData(
    "bcompanyName",
    "Company Name",
    0,
    35,
    "string",
    false
  )
  addErrorAndSanitizedData(
    "bstreetAddressOne",
    "Street address line 1",
    1,
    100,
    "string",
    true
  )
  addErrorAndSanitizedData("bstreetAddressTwo", "", 0, 254, "string", false)
  addErrorAndSanitizedData("bcity", "City", 3, 25, "string", true)
  addErrorAndSanitizedData("bstate", "State", 1, 254, "string", true);
  addErrorAndSanitizedData("bpostCode", "Post code", 2, 9, "postCode", true)
  addErrorAndSanitizedData("bphone", 'Phone number', 8, 15, "phone", true );
  addErrorAndSanitizedData("bemail", "Email", 4, 254, "email", true)

  // The data.createAccount is a boolean value.
  //sanitizedData.createAccount = data.createAccount
  addErrorAndSanitizedData("bborderNotes", "", 0, 254, "string", false)

  //shipping      
  if (data.shipToDifferentAddress) {
    addErrorAndSanitizedData("firstName", "First name", 2, 35, "string", true)
    addErrorAndSanitizedData("lastName", "Last name", 2, 35, "string", true)
    addErrorAndSanitizedData(
      "companyName",
      "Company Name",
      0,
      35,
      "string",
      false
    )
    addErrorAndSanitizedData(
      "streetAddressOne",
      "Street address line 1",
      1,
      100,
      "string",
      true
    )
    addErrorAndSanitizedData("streetAddressTwo", "", 0, 254, "string", false)
    addErrorAndSanitizedData("city", "City", 3, 25, "string", true)
    addErrorAndSanitizedData("state", "State", 1, 254, "string", true);
    addErrorAndSanitizedData("postCode", "Post code", 2, 9, "postCode", true)
    addErrorAndSanitizedData("phone", 'Phone number', 8, 15, "phone", true );
    addErrorAndSanitizedData("email", "Email", 4, 254, "email", true)

    // The data.createAccount is a boolean value.
    //sanitizedData.createAccount = data.createAccount
    addErrorAndSanitizedData("borderNotes", "", 0, 254, "string", false)
  }

  return {
    sanitizedData,
    errors,
    isValid: isEmpty(errors),
  }
}

export default validateAndSanitizeCheckoutForm
