import React, { useEffect, useState, useRef } from "react"
import { Alert, Button } from "reactstrap"
import scrollTo from "gatsby-plugin-smoothscroll"

import { useLiveStream } from "../../hooks"
import { REVISION_VER } from "../../../utils/constants"

const UpdateNotification = () => {
  const {
    loading: isStreamFieldsLoading,
    fields: liveStreamFields,
  } = useLiveStream(true)
  const [showNotification, setShowNotification] = useState(false)

  const updateClick = () => {
    console.log("updateClick", liveStreamFields)
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
      for (let registration of registrations) {
        console.log("registration", registration)
        registration.update().then(result => {
          console.log("update result", result)
          setShowNotification(false)
          window.location.reload(true)
        })
      }
    })
  }

  useEffect(() => {
    if (!isStreamFieldsLoading && !showNotification) {
      if (REVISION_VER !== liveStreamFields.buildId) {
        setShowNotification(true)
      }
    }
  }, [liveStreamFields, showNotification])

  useEffect(() => {
    if (showNotification) {
      scrollTo("#updateNotification", "center")
    }
  }, [showNotification])

  return (
    <Alert
      id="updateNotification"
      style={{
        textAlign: "center",
        display: !showNotification ? "none" : "block",
      }}
    >
      New Update available{" "}
      <Button type="button" onClick={updateClick}>
        Update now!
      </Button>
    </Alert>
  )
}

export default UpdateNotification
