import { useMemo, useCallback } from "react";
import { gql } from "graphql-tag";
import { useQuery } from "@apollo/react-hooks"
import { freeShippingQuery } from "../graphql/queries";
import ShippingChargeCalculator from "../utils/zipZone";
import { useCart } from "../hooks";

const useShipping = () => {
  const { state: { items}, cartActions } = useCart();

  const productIds = items.map(p => p.productId).toString();

  const shippingCalculateHandler = useCallback((zipCode, shippingMethod) => {
    if (zipCode && shippingMethod !== "pickupLocation") {
      const zipZone = new ShippingChargeCalculator(zipCode);
      const zone = zipZone.getZoneByZipCode();
    
      if (zone > 0) {
        let totalShippingCharge = items.reduce((acc, curr) => {
          const { ground, secondDay } = curr.ACFEcommerce;
          let shippingCharge = 0;
          if (ground && shippingMethod === "ground") {
            shippingCharge = ground[0][`zone${zone}`];
          } else if (secondDay && shippingMethod === "express") {
            shippingCharge = secondDay[0][`zone${zone}`];
          }
          return acc + shippingCharge;
        }, 0);

        if (totalShippingCharge > 0) totalShippingCharge += zipZone.getZipSurcharge();
        cartActions.setShippingCost(totalShippingCharge);
      }
    } else {
      cartActions.setShippingCost(0);
    }
  }, [productIds]);

  return useMemo(() => ({
    shippingCalculateHandler,
  }), [shippingCalculateHandler]);
}

const useFreeShipping = () => {
  const data = useQuery(gql`${freeShippingQuery}`);
  return useMemo(() => data.wpgraphql.themeGeneralSettings.ACFShippingSiteWide, [data]);
}

export {
  useFreeShipping,
  useShipping,
}