import { 
  useContext, 
  useState, 
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { CartContext } from "../contexts/CartContext";
import { removeCartItemQuery } from "../graphql/mutations";

const useRemoveCart = () => {
  const { state, actions: cartActions } = useContext(CartContext);
  const [removeCartItemMutation] = useMutation(gql`${removeCartItemQuery}`);
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    if (inProgress) setInProgress(false)
  }, [state.version, inProgress]);

  const removeCartItemHandler = useCallback(async productId => {
    const productToRemove = state.items.find(
      product => product.productId === productId
    )

    if (productToRemove) {
      //setInProgress(true)
      cartActions.removeCartItem(productId);

      try {
        const { data } = await removeCartItemMutation({
          variables: {
            input: {
              clientMutationId: productToRemove.clientMutationId,
              keys: [ productToRemove.key ],
            },
          },
        })

        if (data && data.removeItemsFromCart) {
         console.log("Cart item is removed successfully", data); 
        }
      }
      catch (e) {
        console.error("Error", e);
      }
    }
  }, [state, cartActions]);

  return useMemo(() => ({
    removeCartItemHandler,
    inProgress,  
  }), [removeCartItemHandler, inProgress]);
}

export {
  useRemoveCart
}
