import { useStaticQuery, graphql } from "gatsby";

const useThemeSettings = () => {		
	const data = useStaticQuery(graphql`
		query FreeShippingQuery {
			wpgraphql {
				themeGeneralSettings {
					ACFShippingSiteWide {
						amountExtraForPriority
						amountForFreeShipping
						shippingSitewide
						noWeightPercent
						noWeightBaseRate
					}
				}
			}
		}
	`)

	return data;
}

export default useThemeSettings;