import React, { useContext } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import {
  Nav,
  NavItem,
  Button,
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap"
import styled from "styled-components"

import { ShoppingCart } from "styled-icons/fa-solid/ShoppingCart"
import { WORDPRESS_URL } from "../../utils/constants"
// import LoginModal from "./Forms/LoginPopup"
// import Logout from "./logout"
// import { getLocalStorageItem } from "../../utils/helper"
import { CartContext } from "../contexts/CartContext"

// const CartCta = styled(ShoppingCart)`
//   color: #0085d9;
//   width: 1rem;
// `
// const CartWhite = styled(ShoppingCart)`
//   color: #fff;
//   width: 1rem;
// `

const MENU_QUERY = graphql`
  fragment MenuFields on WPGraphQL_MenuItem {
    id
    label
    url
    menuItemId
  }

  query GET_MENU_ITEMS {
    wpgraphql {
      menuItems(where: { location: PRIMARY }) {
        nodes {
          ...MenuFields
          childItems {
            nodes {
              ...MenuFields
              childItems {
                nodes {
                  ...MenuFields
                  childItems {
                    nodes {
                      ...MenuFields
                      childItems {
                        nodes {
                          ...MenuFields
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const Menu = () => {
  const {
    wpgraphql: {
      menuItems: { nodes: menus },
    },
  } = useStaticQuery(MENU_QUERY)

  return (
    <>
      <Nav id="main-menu" className="navbar-nav">
        {menus &&
          menus.length &&
          menus.map(menu => {
            if (menu.childItems.nodes.length) {
              return renderMenuWithSubMenu(menu)
            } else {
              return renderMenuItems(menu)
            }
          })}
      </Nav>
    </>
  )
}

const createLocalLink = url => {
  if (`#` === url) {
    return null
  }
  if (url.includes("sewing-classes")) {
    const date = new Date()
    url = `${url}/${date.getFullYear()}/${date.getMonth() + 1}`
  }
  return url.replace(WORDPRESS_URL, ``)
}

const renderMenuWithSubMenu = menu => (
  <UncontrolledDropdown nav inNavbar key={menu.menuItemId}>
    <DropdownToggle nav caret>
      {menu.label}
    </DropdownToggle>
    <DropdownMenu left="true">
      {menu.childItems.nodes &&
        menu.childItems.nodes.map(childMenu => (
          <DropdownItem key={childMenu.menuItemId}>
            <Link
              title={childMenu.label}
              to={createLocalLink(childMenu.url)}
              className="nav-link"
            >
              {childMenu.label}
            </Link>
          </DropdownItem>
        ))}
    </DropdownMenu>
  </UncontrolledDropdown>
)

const renderMenuItems = menu => (
  <NavItem key={menu.menuItemId}>
    <Link
      title={menu.label}
      to={menu.label === `Blog` ? `/blog` : createLocalLink(menu.url)}
      className="nav-link"
    >
      {menu.label}
    </Link>
  </NavItem>
)

// export const Actions = () => {
//   // const [popup, togglePopup] = useState(false)
//   // const [cart, toggleCart] = useState(false)
//   const {
//     state: { items: bucket },
//   } = useContext(CartContext)

//   if (typeof window === "undefined") {
//     return <></>
//   }

//   const { AnimateOnChange } = require("react-animation")

//   console.log("Actions.bucket", bucket)
//   // const isLoggedIn = getLocalStorageItem("RMSV_AUTH_TOKEN")

//   return (
//     <NavItem>
//       <AnimateOnChange
//         animationIn="bounceIn"
//         animationOut="bounceOut"
//         durationOut={500}
//       >
//         {bucket.length === 0 ? (
//           <Button outline color="cta">
//             <span>
//               <CartCta />
//             </span>{" "}
//           </Button>
//         ) : (
//           <Link title="Cart" to="/cart">
//             <Button color="cta">
//               <span>
//                 <CartWhite />
//               </span>{" "}
//               {bucket.length}
//             </Button>
//           </Link>
//         )}
//       </AnimateOnChange>
//     </NavItem>
//   )
// }

export default Menu
