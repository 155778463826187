import { useCallback, useMemo, useContext } from "react"
import { useStripe } from "@stripe/react-stripe-js"
import uuid from "uuid"

import { CheckoutContext } from "../contexts/CheckoutContext"
import validateAndSanitizeCheckoutForm from "../validator/checkout"
import { useCart } from "."

const useStripeCheckout = () => {
  const stripe = useStripe()
  const { state: checkout, actions: checkoutActions } = useContext(
    CheckoutContext
  )
  const {
    state: { totalValue, items },
  } = useCart()
  // const { state: checkout, checkoutActions } = useCheckout();
  // const [error, setError] = useState(null);

  const stripeCheckoutHandler = useCallback(async () => {
    try {
      console.log("checkout.data", checkout.data)
      const checkoutDetail = validateAndSanitizeCheckoutForm(checkout.data)
      if (!checkoutDetail.isValid) {
        throw checkoutDetail.error
      }

      if (totalValue <= 0) {
        //TODO: handle error
      }

      const { sanitizedData: shippingDetail } = checkoutDetail

      console.log("checkoutDetail", checkoutDetail)
      console.log("item", items)
      const stripeItems = items.map(item => ({
        id: item.id,
        quantity: item.quantity,
        variationId: item.variationId,
      }))

      const data = {
        lineItems: stripeItems,
        clientMutationId: uuid(), //TODO: store outside of items array
        checkout: {
          billing: {
            address1: shippingDetail.bstreetAddressOne,
            address2: shippingDetail.bstreetAddressTwo,
            city: shippingDetail.bcity,
            company: shippingDetail.bcompanyName,
            email: shippingDetail.bemail,
            firstName: shippingDetail.bfirstName,
            lastName: shippingDetail.blastName,
            phone: shippingDetail.bphone,
            postcode: shippingDetail.bpostCode,
            state: shippingDetail.bstate,
          },
          shippingLine: {
            shippingMethod: checkout.data.shippingMethod,
            pickupLocation:
              checkout.data.shippingMethod === "localPickup"
                ? checkout.data.pickupLocation
                : "",
          },
        },
      }

      if (checkout.data.shipToDifferentAddress) {
        data.checkout.shipping = {
          address1: shippingDetail.streetAddressOne,
          address2: shippingDetail.streetAddressTwo,
          city: shippingDetail.city,
          company: shippingDetail.companyName,
          email: shippingDetail.email,
          firstName: shippingDetail.firstName,
          lastName: shippingDetail.lastName,
          phone: shippingDetail.phone,
          postcode: shippingDetail.postCode,
          state: shippingDetail.state,
        }
      }

      console.log("metadata", data)

      const request = await fetch(
        "/.netlify/functions/create-checkout-session",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          cache: "no-cache",
          body: JSON.stringify(data),
        }
      )

      const response = await request.json()
      if (response.error) {
        throw new Error(response.error)
      }

      console.log("response", response)

      const { error } = await stripe.redirectToCheckout({
        sessionId: response.sessionId,
      })

      if (error) {
        console.error(error)
      }
      // const cardElement = elements.getElement(CardElement);
      // if (!cardElement) {
      //   //TODO: handle error
      // }

      // const result = await stripe.createPaymentMethod({
      //   type: "card",
      //   card: cardElement,
      // });

      // if (result.error) {
      //   throw new Error(result.error);
      //   //TODO: handle error
      // }

      // console.log("handleFormSubmit.createPaymentMethod", result)
      // const { id:paymentMethodId } = result.paymentMethod;
      // const data = {
      //   paymentMethodId: paymentMethodId,
      //   orderAmount: totalValue,
      // }

      // const request = await fetch("/.netlify/functions/checkout-stripe", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   cache: "no-cache",
      //   body: JSON.stringify(data),
      // });

      // const response = await request.json()
      // console.log("handleFormSubmit.response", response);
      // if (response.error) {
      //   console.error("error", response)
      //   // setError(response.error)
      // } else if (response.requiresAction) {
      //   await handleAction(
      //     response.clientSecret,
      //     checkoutDetail.sanitizedData
      //   )
      // } else {
      //   await orderComplete(response.clientSecret);
      // }
      // setProcessing(false)
    } catch (e) {
      console.error("error", e)
      throw e
      // setProcessing(false)
    }
  }, [checkout, stripe])

  // const handleAction = async (clientSecret) => {
  //   const data = await stripe.handleCardAction(clientSecret)

  //   if (data.error) {
  //     // setError("Your card was not authenticated, please try again")
  //   } else if (data.paymentIntent.status === "requires_confirmation") {
  //     const result = await fetch("/.netlify/functions/checkout-stripe", {
  //       method: "POST",
  //       cache: "no-cache",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         paymentIntentId: data.paymentIntent.id,
  //       }),
  //     })

  //     const json = await result.json()
  //     if (json.error) {
  //       // setError(json.error)
  //     } else {
  //       await orderComplete(clientSecret)
  //     }
  //   }
  // }

  // var orderComplete = async (clientSecret) => {
  //   const result = await stripe.retrievePaymentIntent(clientSecret)
  //   var paymentIntent = result.paymentIntent
  //   console.log("orderComplete.result", result);
  //   console.log("orderComplete.paymentIntent", paymentIntent);
  //   if (paymentIntent && paymentIntent.status === "succeeded") {
  //     const checkoutResult = await checkoutHandler(paymentIntent.id, paymentIntent.payment_method);

  //     // setCheckoutSuccess(true)
  //   } else if (result.error) {
  //     // setError(result.error)
  //   }
  // }

  return useMemo(
    () => ({
      state: checkout,
      stripeCheckoutHandler,
      checkoutActions,
    }),
    [checkout, stripeCheckoutHandler]
  )
}

export { useStripeCheckout }
