import React, { useReducer, useEffect } from "react"

import ContextStorage from "./ContextStorage"
import { getLocalStorageItem } from "../../utils/helper"
import { useThemeSettings, useCart } from "../hooks";

const { persist, restore } = ContextStorage("Cart")
const CheckoutContext = React.createContext()

const defaultState = {
  data: null,
  version: 0,
}
const init = (name, initialState, initializer) => {
  const initializerInitialState = initializer(initialState)
  const newInitState = {
    ...defaultState,
    data: {
      ...initializerInitialState,
    },
  }
  return restore(newInitState, name)
}
const reducer = (state, action) => {
  let nextState
  switch (action.type) {
    case "setData":
      nextState = {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        version: state.version + 1,
      }
      break
    case "setErrors":
      nextState = {
        ...state,
        data: {
          ...state.data,
          errors: {
            ...action.payload,
          },
        },                                                                              
        version: state.version + 1,
      }
      break
    case "reset":
      nextState = {
        ...action.payload,
      }
      break
    default:
      throw new Error(`Unable to handle the action type ${action.type}`)
  }
  return persist(nextState)
}

const CheckoutContextProvider = ({ children, name, initialState = {} }) => {
  const jsonUserData = getLocalStorageItem("RMSV_AUTH_TOKEN")
  const userData = jsonUserData && JSON.parse(jsonUserData)
  
  const initializer = initialState => {
    // let shippingStatus = ""
    // if (initialState.shippingMethod) {
    //   shippingStatus = ""
    // }
    return {
      firstName: userData?.customer?.shipping?.firstName,
      lastName: userData?.customer?.shipping?.lastName,
      companyName: userData?.customer?.shipping?.company,
      country: "US",
      streetAddressOne: userData?.customer?.shipping?.address1,
      streetAddressTwo: userData?.customer?.shipping?.address2,
      city: userData?.customer?.shipping?.city,
      state: userData?.customer?.shipping?.state,
      postCode: userData?.customer?.shipping?.postcode,
      phone: userData?.customer?.shipping?.phone,
      email: userData?.user?.email,
      createAccount: false,
      orderNotes: "",
      // paymentMode: "",
      errors: {},
      // shippingStatus: "",
      shippingMethod: "",
      shipToDifferentAddress: false,
      bfirstName: userData?.customer?.billing?.firstName,
      blastName: userData?.customer?.billing?.lastName,
      bcompanyName: userData?.customer?.billing?.company,
      bcountry: "US",
      bstreetAddressOne: userData?.customer?.billing?.address1,
      bstreetAddressTwo: userData?.customer?.billing?.address2,
      bcity: userData?.customer?.billing?.city,
      bstate: userData?.customer?.billing?.state,
      bpostCode: userData?.customer?.billing?.postcode,
      bphone: userData?.customer?.billing?.phone,
      bemail: userData?.user?.email,
      bcreateAccount: false,
      borderNotes: "",
      // bpaymentMode: "",
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState, initialState =>
    init(name, initialState, initializer)
  )
  const actions = {
    setData: data => dispatch({ type: "setData", payload: data }),
    setErrors: errors => dispatch({ type: "setErrors", payload: errors }),
    reset: () =>
      dispatch({
        type: "reset",
        payload: init(name, initialState, initializer),
      }),
  }

  const value = {
    state,
    actions,
  }

  const themeSettings = useThemeSettings();
  const { state: { subTotal }, cartActions } = useCart();

  useEffect(() => {
    const shippingSettings = themeSettings.wpgraphql.themeGeneralSettings.ACFShippingSiteWide;

    // if (!state.data.shippingMethod) {
    //   if (shippingSettings.amountForFreeShipping > subTotal) {
    //     actions.setData({
    //       ...state.data,
    //       shippingMethod: "fedex"
    //     })
    //   } else {
    //     actions.setData({
    //       ...state.data,
    //       shippingMethod: "free_shipping"
    //     })
    //   }
    // }

    if (state.data.shippingMethod) {
      let shippingCost = 0;     

      if (state.data.shippingMethod === "fedex") {
        if (shippingSettings.noWeightBaseRate) {
          shippingCost = shippingSettings.noWeightBaseRate;
        }
        if (shippingSettings.noWeightPercent && !isNaN(shippingSettings.noWeightPercent)) {
          const shippingCharge = (shippingSettings.noWeightPercent / 100) * subTotal;
          shippingCost += shippingCharge;
        }
      }

      if (state.data.shippingMethod === "fedex_priority") {
        if (shippingSettings.noWeightBaseRate) {
          shippingCost = shippingSettings.noWeightBaseRate;
        }
        if (shippingSettings.noWeightPercent && !isNaN(shippingSettings.noWeightPercent)) {
          const shippingCharge = (shippingSettings.noWeightPercent / 100) * subTotal;
          shippingCost += shippingCharge;
        }
        shippingCost += shippingSettings.amountExtraForPriority;
      }

      cartActions.setShippingCost(shippingCost);
    }
  }, [state])

  return (
    <CheckoutContext.Provider value={value}>
      {children}
    </CheckoutContext.Provider>
  )
}
const CheckoutContextConsumer = CheckoutContext.Consumer
export { CheckoutContext, CheckoutContextConsumer, CheckoutContextProvider }
