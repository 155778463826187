import { useContext, useMemo } from "react"
import { CartContext } from "../contexts/CartContext"
import { getPrice } from "../utils/product"

const useCart = () => {
  const { state, actions: cartActions } = useContext(CartContext)

  const isProductExist = product => {
    let productId = product
    if (typeof product === "object") productId = product.productId
    return (
      state.items.filter(product => product.productId === productId).length > 0
    )
  }

  const addToCart = (product, qty, variationId) => {
    if (typeof fbq === `function`) {
      window.fbq("track", "AddToCart", {
        content_name: product.name,
        content_ids: [product.id, variationId],
        content_type: "product",
        currency: "USD",
        contents: [{ id: product.id, quantity: qty }],
        value: Math.round(getPrice(product.price) / 20, 2),
      })
    }

    cartActions.addToCart(product, qty, variationId)
  }

  const withCurrency = value => {
    let currencyToUse = state.currencySymbol || "$"
    if (state.currencySymbol) currencyToUse = state.currencySymbol

    return `${currencyToUse}${Number(value)}`
  }

  return useMemo(
    () => ({
      state,
      isProductExist,
      withCurrency,
      cartActions: {
        ...cartActions,
        addToCart,
      },
    }),
    [state]
  )
}

export { useCart }
