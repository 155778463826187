export const setAuthLocalStorage = data => {
  if (typeof window !== "undefined") {
    localStorage.setItem("RMSV_AUTH_TOKEN", JSON.stringify(data))
  }
}

export const getLocalStorageItem = key => {
  let item
  if (typeof window !== "undefined") {
    item = localStorage.getItem(key)
  }
  return item
}
