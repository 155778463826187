import { useEffect, useState, useMemo } from "react"
import firebase from "gatsby-plugin-firebase"

const useLiveStream = (listen = true) => {
  const [loading, setLoading] = useState(true)
  const [streamFields, setStreamFields] = useState()

  const updateHandler = fields => {
    return firebase
      .database()
      .ref(`/${process.env.FIREBASE_BASE_URL}`)
      .update(fields)
  }

  useEffect(() => {
    const ref = firebase.database().ref(`/${process.env.FIREBASE_BASE_URL}`)
    ref.on("value", snapshot => {
      const streamFields = snapshot.val()
      setStreamFields(streamFields)
      setLoading(false)

      // if (!listen) ref.off();
    })

    return () => {
      ref.off()
    }
  }, [])

  return useMemo(
    () => ({
      fields: {
        ...streamFields,
      },
      updateHandler,
      loading,
    }),
    [streamFields, loading]
  )
}

export default useLiveStream
