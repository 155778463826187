import {
  useState, 
  useCallback,
  useMemo,
} from "react"
// import { useMutation } from "@apollo/react-hooks"
// import gql from "graphql-tag"
import uuid from "uuid"
// import { addToCartQuery } from "../graphql/mutations";
import { useCart, useUpdateCartQty } from ".";
// import { toNumber } from "../utils/utilities";

const useAddToCart = () => {
  const [inProgress, setInProgress] = useState(false);
  // const [addToCartMutation] = useMutation(gql`${addToCartQuery}`);

  const { isProductExist, cartActions, state: cart } = useCart();
  const { updateQtyHandler, inProgress: updateQtyInProgress } = useUpdateCartQty();

  // useEffect(() => {
  //   if (inProgress) setInProgress(false);
  // }, [state.version, inProgress]);

  const addToCartHandler = useCallback(async (product, qty, variationId) => {
    //setInProgress(true);
    const { items } = cart;
    if (!isProductExist(product)) {
      let clientMutationId
      if (items.length > 0 && items[0].clientMutationId)
        clientMutationId = items[0].clientMutationId
      else clientMutationId = uuid();

      cartActions.addToCart({
        clientMutationId,
        ...product,
        },
        qty,
        variationId,
      );
    } else {      
      await updateQtyHandler(product.productId, qty);
      setInProgress(updateQtyInProgress);
    }
  }, [cart.version]);

  // const addToCartHandler = useCallback(async (product, qty) => {
  //   //setInProgress(true);
  //   const { items } = cart;
  //   if (!isProductExist(product)) {
  //     let clientMutationId
  //     if (items.length > 0 && items[0].clientMutationId)
  //       clientMutationId = items[0].clientMutationId
  //     else clientMutationId = uuid()

  //     cartActions.addToCart({
  //       clientMutationId,
  //       ...product,
  //       },
  //       qty
  //     )

  //     const productInput = {
  //       clientMutationId,
  //       productId: product.productId,
  //       quantity: qty,
  //       variationId: 0,
  //       variation: [],
  //       extraData: "",
  //     }

  //     try {
  //       const { data } = await addToCartMutation({
  //         variables: {
  //           input: productInput,
  //         },
  //       });

  //       console.log("addToCartMutation", data);

  //       if (data && data.addToCart) {
  //         const { clientMutationId, cartItem } = data.addToCart

  //         const productId = Number(atob(cartItem.product.id).split(":")[1])
  //         const newProduct = {
  //           id: cartItem.product.id,
  //           clientMutationId,
  //           key: cartItem.key,
  //           productId,
  //           name: cartItem.product.name,
  //           quantity: cartItem.quantity,
  //           price: toNumber(cartItem.subtotal),
  //           displayPrice: cartItem.subtotal,
  //           image: product.image,
  //           ACFEcommerce: {
  //             ...product.ACFEcommerce
  //           },
  //         }
  //         cartActions.updateCart(newProduct);
  //         setInProgress(false);
  //       }
  //     } catch (e) {
  //       cartActions.removeCartItem(product.productId);
  //       console.log("Exception is thrown while adding item to cart", e);
  //     }
  //   } else {      
  //     await updateQtyHandler(product.productId, qty);
  //     setInProgress(updateQtyInProgress);
  //   }
  // }, [cart.version]);

  return useMemo(() => ({
    addToCartHandler,
    inProgress,
  }), [addToCartHandler, inProgress]);
}

export {
  useAddToCart
}
