import { useCallback } from "react"
import { setAuthLocalStorage } from "../../utils/helper"

const useLoginUserHandler = () => {
  return useCallback(
    async (LoginUser, uuid, username, password) => {
      let result;
      try {
        result = await LoginUser({
          variables: {
            clientMutationId: uuid(),
            username,
            password,
          },
        })
        setAuthLocalStorage(result.data.login)
      } catch (err) {
        result = err.message.split("GraphQL error: ")[1]
      }
      return result
    },
    []
  )
}

const useRegisterUserHandler = () => {
  return useCallback(
    async (RegisterUser, input) => {
      let result;
      try {
        result = await RegisterUser({
          variables: { input },
        })
      } catch (err) {
        result = err.message.split("GraphQL error: ")[1]
      }
      return result
    },
    []
  )
}

const useForgotPasswordResetEmail = () => {
  return useCallback(
    async (SendPasswordResetEmail, uuid, username) => {
      let result;
      try {
        result = await SendPasswordResetEmail({
          variables: {
            input: {
              clientMutationId: uuid(),
              username,
            },
          },
        })
      } catch (err) {
        result = err.message.split("GraphQL error: ")[1]
      }
      return result
    },
    []
  )
}

const useResetPassword = () => {
  return useCallback(
    async (ResetUserPassword, uuid, key, login, password) => {
      let result;
      try {
        result = await ResetUserPassword({
          variables: {
            input: {
              clientMutationId: uuid(),
              key,
              login,
              password,
            },
          },
        })
      } catch (err) {
        result = err.message.split("GraphQL error: ")[1]
      }
      return result
    },
    []
  )
}

export {
  useLoginUserHandler,
  useRegisterUserHandler,
  useForgotPasswordResetEmail,
  useResetPassword,
}
