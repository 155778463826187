
const useNoCodeApi = (sheetId, tabId) => {
  const handler = async (data) => {
    try {
      const request = await fetch("/.netlify/functions/no-code-api", {
        method: "POST",
        body: JSON.stringify({
          sheetId,
          tabId,
          content: data,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const response = await request.json();
      return response;
    } catch (e) {
      console.log("exception", e);
    }
  }

  return {
    handler,
  }
}

export default useNoCodeApi;