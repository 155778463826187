import React from "react"
import { NavItem } from "reactstrap"
import { Phone } from "styled-icons/fa-solid/Phone"
import styled from "styled-components"
import { BRAND_PHONE } from "../../utils/constants"

const brandPhone = `tel:${BRAND_PHONE}`

const PhoneIcon = styled(Phone)`
  color: #214495;
  width: 1.5rem;
`

const PhoneNumber = () => {
  return (
    <>
      <NavItem className="ml-3 my-auto">
        <a href={brandPhone} className="phone-small">
          <PhoneIcon />
        </a>
        <span className="phone-large">
          Call Us:
          <a href={brandPhone}> 1-{BRAND_PHONE}</a>
        </span>
      </NavItem>
    </>
  )
}

export default PhoneNumber
