import gql from 'graphql-tag';

export const LOGIN_USER = gql`
  mutation LoginUser(
    $clientMutationId: String!
    $username: String!
    $password: String!
  ) {
    login(
      input: {
        clientMutationId: $clientMutationId
        username: $username
        password: $password
      }
    ) {
      user {
        id
        userId
        email
        username
      }
      customer {
        shipping {
          postcode
          address1
          city
          address2
          company
          country
          firstName
          lastName
        }
        billing {
          address1
          address2
          city
          postcode
          phone
          state
          company
          country
        }
        jwtAuthExpiration
        jwtAuthToken
        jwtRefreshToken
        jwtUserSecret
      }
    }
  }
`

export const REGISTER_USER = gql`
  mutation RegisterUser($input: RegisterUserInput!) {
    registerUser(input: $input) {
      user {
        id
        name
      }
    }
  }
`

export const SEND_PASSWORD_RESET_EMAIL = gql`
  mutation SendPasswordResetEmail($input: SendPasswordResetEmailInput!) {
    sendPasswordResetEmail(input: $input) {
      clientMutationId
    }
  }
`
export const RESET_USER_PASSWORD = gql`
  mutation ResetUserPassword($input: ResetUserPasswordInput!) {
    resetUserPassword(input: $input) {
      clientMutationId
    }
  }
`

export const addToCartQuery = `
  mutation addToCart($input: AddToCartInput!) {
    addToCart( input: $input ) {
      clientMutationId
      cartItem {
        key
        product {
          id
          name
        }
        variation {
          id
        }
        quantity
        subtotal
        subtotalTax
        total
        tax
      }
    }
  }
`

export const removeCartItemQuery = `
  mutation removeItemsFromCart( $input: RemoveItemsFromCartInput! ) {
    removeItemsFromCart( input: $input ) {
      clientMutationId
    }
  }
`

export const updateCartQtyQuery = `
  mutation updateItemQuantities( $input: UpdateItemQuantitiesInput! ) {
    updateItemQuantities( input: $input ) {
      clientMutationId
      items {
        key
        product {
          id
          name
        }
        quantity
      }
    }
  }
`

export const checkoutQuery = `
  mutation checkout( $input: CheckoutInput! ) {
    checkout( input: $input ) {
      clientMutationId
      order {
        id
      }
    }
  }
`