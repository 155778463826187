import PropTypes from "prop-types"
import React, { useState } from "react"
import { Link } from "gatsby"
import {
  Container,
  Collapse,
  Nav,
  Navbar,
  NavbarToggler,
  NavbarBrand,
} from "reactstrap"
import Menu from "./menu"
// import Search from "./search"
// import { Actions } from "./menu"
import PhoneNumber from "./PhoneNumber"
// import { SearchIcon } from "./search/styles"
import logo from "../images/logo.svg"
import constants from "../../utils/constants"

const brandShortAlt = constants.BRAND_SHORTNAME + " Logo"

// const searchIndices = [
//   {
//     name: `rockymountainsewing_comsearchable_posts`,
//     title: `Blog Posts`,
//     hitComp: `PostHit`,
//   },
// ]

const Header = () => {
  return (
    <header>
      <Navigation>
        <Menu />
      </Navigation>
    </header>
  )
}

const Navigation = ({ children }) => {
  const [navOpen, toggleNav] = useState(false)
  // const [searchOpen, setSearchOpen] = useState(false)

  return (
    <>
      <Navbar color="light" light expand="xl" fixed="top">
        <Container>
          <NavbarBrand tag={Link} to="/">
            <img
              // src={require("../images/logo.png")}
              src={logo}
              alt={brandShortAlt}
            />
          </NavbarBrand>
          <Nav className="mx-auto my-auto">
            {/* <Actions /> */}
            <PhoneNumber />
            {/* <NavItem>
              <Button
                className="mx-2"
                type="button"
                data-toggle="collapse"
                data-target="#externalSearchBar"
                onClick={() => setSearchOpen(!searchOpen)}
              >
                <SearchIcon />
              </Button>
            </NavItem> */}
          </Nav>
          <NavbarToggler onClick={() => toggleNav(!navOpen)} />

          <Collapse isOpen={navOpen} navbar className="flex-row-reverse">
            {children}
          </Collapse>
        </Container>
      </Navbar>
      {/* <Collapse
        id="externalSearchBar"
        isOpen={searchOpen}
        className="search-bar"
      >
        <Container>
          <Row>
            <Col lg="10" className="mx-auto">
              <Search collapse indices={searchIndices} />
            </Col>
          </Row>
        </Container>
      </Collapse> */}
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
